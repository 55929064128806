<table class="table work-time-controlling-table">
    <thead>
    <tr>
        @for (header of tableHeaders; track header.translationKey) {
            <th scope="col">
                @if (header.hasOwnProperty('sortBy')) {
                    <button dvlibButton="link-button" (click)="toggleSort.emit(header.sortBy!)">
                        <span [translate]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.' + header.translationKey"></span>
                        <dvlib-icon [iconName]="sortBy() === header.sortBy ? sortOrder() : 'sort'" />
                    </button>
                } @else {
                    <span [translate]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.' + header.translationKey"></span>
                }
            </th>
        }
        <th scope="col"></th>
    </tr>
    </thead>
    @for (row of data(); track row.id) {
        <tbody [class.expanded]="row.expanded">
        <tr class="row-employee">
            <td>
                <a uiSref="base.personal.angestellte.main.anstellungen.verlauf"
                   [uiParams]="{id: row.id}">
                    {{ row.angestellteDisplayName }}
                </a>
            </td>
            <td>{{ row.targetHours | dvHoursFormat }}</td>
            <td>{{ row.plannedHours | dvHoursFormat }}</td>
            <td>{{ row.ist }}</td>
            <td>{{ row.abwesenheit }}</td>
            <td>{{ row.ferienkontingent }}</td>
            <td>
                @if (row.expanded) {
                    <button class="btn btn-link toggle"
                            [attr.aria-label]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.COLLAPSE_EMPLOYEE' | translate"
                            [tooltip]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.COLLAPSE_EMPLOYEE' | translate"
                            (click)="toggleAngestellte.emit(row.id)">
                        <i class="fa fa-chevron-up"></i>
                    </button>
                } @else {
                    <button class="btn btn-link toggle"
                            [attr.aria-label]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.EXPAND_EMPLOYEE' | translate"
                            [tooltip]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.EXPAND_EMPLOYEE' | translate"
                            (click)="toggleAngestellte.emit(row.id)">
                        <i class="fa fa-chevron-down"></i>
                    </button>
                }
            </td>
        </tr>
            @if (row.expanded) {
                <tr>
                    <td colspan="7" class="row-date-table">
                        <table class="table date-table">
                            <thead>
                            <tr>
                                @if (row.dailyHours.length > 0) {
                                    <th scope="col">{{ row.dailyHours[0].date | dvLocalDateFormat: 'MMMM YYYY' }}</th>
                                } @else {
                                    <th scope="col" translate="WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.DATE"></th>
                                }
                                <th scope="col"
                                    translate="WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.TARGET_HOURS"></th>
                                <th scope="col"
                                    translate="WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.PLANNED_HOURS"></th>
                                <th scope="col" translate="WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.IST"></th>
                                <th scope="col"
                                    translate="WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.ABWESENHEIT"></th>
                                <th scope="col">
                                    <div class="divided-col">
                                        <div translate="WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.FERIENKONTINGIENT"></div>
                                        <div>&nbsp;</div>
                                    </div>
                                </th>
                            </tr>
                            </thead>

                            @for (dayRow of row.dailyHours; track dayRow.date) {
                                <tr class="row-day" [class.expanded]="dayRow.expanded">
                                    <td class="date-cell">
                                        <span>{{ dayRow.date | dvLocalDateFormat:'dd' }}</span>
                                        <span>{{ dayRow.date | dvLocalDateFormat:'D.M.' }}</span>
                                    </td>
                                    <td>{{ dayRow.targetHours | dvHoursFormat }}</td>
                                    <td>{{ dayRow.plannedHours | dvHoursFormat }}</td>
                                    <td>{{ dayRow.ist }}</td>
                                    <td>{{ dayRow.abwesenheit }}</td>
                                    <td class="ferienkontingent">
                                        <div class="value">{{ dayRow.ferienkontingent }}</div>
                                        @if (dayRow.expanded) {
                                            <button class="btn btn-link toggle"
                                                    [attr.aria-label]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.COLLAPSE_DAY_DETAILS' | translate"
                                                    [tooltip]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.COLLAPSE_DAY_DETAILS' | translate"
                                                    (click)="toggleDay.emit({angestellteId: row.id, date: dayRow.date })">
                                                <i class="fa fa-chevron-up"></i>
                                            </button>
                                        } @else {
                                            <button class="btn btn-link toggle"
                                                    [attr.aria-label]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.EXPAND_DAY_DETAILS' | translate"
                                                    [tooltip]="'WORK_TIME_CONTROLLING.WORK_TIME_TABLE.HEADER.EXPAND_DAY_DETAILS' | translate"
                                                    (click)="toggleDay.emit({angestellteId: row.id, date: dayRow.date })">
                                                <i class="fa fa-chevron-down"></i>
                                            </button>
                                        }
                                    </td>
                                </tr>

                                @if (dayRow.expanded) {
                                    <!-- nested section underneath day row -->
                                    <tr class="row-day-detail">
                                        <td colspan="6">
                                            <form>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <textarea type="text"
                                                                  [rows]="5"
                                                                  [ngModel]="''"
                                                                  class="form-control"
                                                                  name="notes"
                                                                  dvlibElasticTextInput
                                                                  [placeholder]="'Notitzen'"
                                                                  [attr.aria-label]="'Notitzen'"
                                                                  [tooltip]="'Notitzen'">
                                                        </textarea>
                                                    </div>
                                                </div>
                                            </form>
                                        </td>
                                    </tr>
                                }
                            }
                        </table>
                    </td>
                </tr>
            }
        </tbody>
    }
</table>

