import {NgClass, NgTemplateOutlet} from '@angular/common';
import {ChangeDetectionStrategy, Component, input, output} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {
    ButtonComponent,
    DvHoursFormatPipe,
    DvLocalDateFormatPipe,
    ElasticTextInputDirective,
    IconComponent,
} from '@dv/shared/angular';
import {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';
import {TranslateModule} from '@ngx-translate/core';
import {UIRouterModule} from '@uirouter/angular';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {SortBy, SortOrder, WorkTimeControllingTableData} from './work-time-controlling-table.models';

type TableHeader = {
    translationKey: string;
    sortBy?: SortBy;
};

@Component({
    selector: 'dv-work-time-controlling-table',
    standalone: true,
    imports: [
        NgClass,
        TooltipModule,
        ElasticTextInputDirective,
        TranslateModule,
        NgTemplateOutlet,
        FormsModule,
        IconComponent,
        ButtonComponent,
        UIRouterModule,
        DvHoursFormatPipe,
        DvLocalDateFormatPipe,
    ],
    templateUrl: './work-time-controlling-table.component.html',
    styleUrl: './work-time-controlling-table.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkTimeControllingTableComponent {

    public data = input.required<WorkTimeControllingTableData[]>();
    public sortBy = input.required<SortBy>();
    public sortOrder = input.required<SortOrder>();

    public readonly toggleSort = output<SortBy>();
    public readonly toggleAngestellte = output<string>();
    public readonly toggleDay = output<{ angestellteId: string; date: BackendLocalDate }>();

    public tableHeaders: TableHeader[] = [
        {
            translationKey: 'EMPLOYEE',
            sortBy: 'displayname',
        },
        {
            translationKey: 'TARGET_HOURS',
            sortBy: 'targetHours',
        },
        {
            translationKey: 'PLANNED_HOURS',
            sortBy: 'plannedHours',
        },
        {
            translationKey: 'IST',
        },
        {
            translationKey: 'ABWESENHEIT',
        },
        {
            translationKey: 'FERIENKONTINGIENT',
        },
    ];
}
