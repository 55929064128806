import {ChangeDetectionStrategy, Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {HeaderComponent} from '@dv/shared/angular';
import {TranslateModule} from '@ngx-translate/core';
import {UIRouterModule} from '@uirouter/angular';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {CONTROL_STATE, SETTINGS_STATE, WORK_TIME_MODELS_STATE} from '../work-time-controlling.states';

@Component({
    selector: 'dv-work-time-controlling',
    standalone: true,
    imports: [
        RouterOutlet,
        UIRouterModule,
        HeaderComponent,
        TranslateModule,
        TooltipModule,
    ],
    templateUrl: './work-time-controlling.component.html',
    styles: `
        :host {
            display: block;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkTimeControllingComponent {
    public states = [CONTROL_STATE, WORK_TIME_MODELS_STATE, SETTINGS_STATE];
}
