import {ChangeDetectionStrategy, Component, inject, type Signal} from '@angular/core';
import {CrudAccordionComponent, CrudAccordionTemplateDirective} from '@dv/kitadmin/ui';
import {ButtonListComponent, SpinnerComponent} from '@dv/shared/angular';
import {TranslateModule} from '@ngx-translate/core';
import {WorkTimeModelFormComponent} from '../work-time-model-form/work-time-model-form.component';
import {WorkTimeModelsStore} from './work-time-models.store';

@Component({
    selector: 'dv-work-time-models',
    standalone: true,
    imports: [
        TranslateModule,
        CrudAccordionComponent,
        CrudAccordionTemplateDirective,
        WorkTimeModelFormComponent,
        ButtonListComponent,
        SpinnerComponent,
    ],
    providers: [WorkTimeModelsStore],
    templateUrl: './work-time-models.component.html',
    styles: `
    :host {
      display: block;
    }
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkTimeModelsComponent {

    public readonly store = inject(WorkTimeModelsStore);

    public items = this.store.entities;
    public isEmpty = this.store.isEmpty;
    public createMode = this.store.showCreateMode;
    public showDeleteDialog = this.store.showDeleteDialog;
    public isLoading: Signal<boolean> = this.store.isLoadingOrCreating;
}
