<h2 [translate]="model() ? 'WORK_TIME_CONTROLLING.WORK_TIME_MODEL.EDIT' : 'WORK_TIME_CONTROLLING.WORK_TIME_MODEL.NEW'"></h2>
<form [formGroup]="form()" (ngSubmit)="submitForm()">
    <div class="row">
        <div class="col-md-6">
            <input type="text"
                   class="form-control"
                   formControlName="name"
                   [maxlength]="255"
                   [placeholder]="'WORK_TIME_CONTROLLING.WORK_TIME_MODEL.NAME' | translate"
                   [tooltip]="'WORK_TIME_CONTROLLING.WORK_TIME_MODEL.NAME' | translate"
                   [attr.aria-label]="'WORK_TIME_CONTROLLING.WORK_TIME_MODEL.NAME' | translate">
        </div>

        <div class="col-md-6">
            @if (!model()) {
                <input type="number"
                       class="form-control"
                       formControlName="weeklyHours"
                       min="0"
                       [placeholder]="'WORK_TIME_CONTROLLING.WORK_TIME_MODEL.WEEKLY_HOURS' | translate"
                       [tooltip]="'WORK_TIME_CONTROLLING.WORK_TIME_MODEL.WEEKLY_HOURS' | translate"
                       [attr.aria-label]="'WORK_TIME_CONTROLLING.WORK_TIME_MODEL.WEEKLY_HOURS' | translate">
            } @else {
                <span class="form-linker">{{ 'COMMON.STUNDEN' | translate:{hours: model()!.weeklyHours} }}</span>
            }
        </div>
    </div>

    <dv-submit-cancel-buttons [isLoading]="isLoading()" (cancel)="handleCancel()">
    </dv-submit-cancel-buttons>
</form>
