<div class="header">
    <div class="row header-row">
        <div class="col-xs-12 header-box">
            <h1 [tooltip]="'WORK_TIME_CONTROLLING.TITLE' | translate" translate="WORK_TIME_CONTROLLING.TITLE"></h1>
            <div class="header-dropdown"></div>
            <nav>
                <ul class="list-inline header-breadcrumb">
                    @for (state of states; track state.name) {
                        <li uiSrefActiveEq="active">
                            <a [uiSref]="state.name"
                               [translate]="state.data.subtitle">
                            </a>
                        </li>
                    }
                </ul>
            </nav>
        </div>
    </div>
</div>

<ui-view></ui-view>
