/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {withDevtools} from '@angular-architects/ngrx-toolkit';
import {computed, inject} from '@angular/core';
import {handleResponse} from '@dv/shared/angular';
import {WorkTimeControlService} from '@dv/shared/backend/api/work-time-control.service';
import type {BackendLocalDate} from '@dv/shared/backend/model/backend-local-date';
import type {JaxWorkTimeControl} from '@dv/shared/backend/model/jax-work-time-control';
import type {JaxWorkTimeControlDailyData} from '@dv/shared/backend/model/jax-work-time-control-daily-data';
import type {JaxWorkTimeControls} from '@dv/shared/backend/model/jax-work-time-controls';
import {DvbDateUtil, DvbRestUtil} from '@dv/shared/code';
import {patchState, signalStore, withComputed, withHooks, withMethods, withState} from '@ngrx/signals';
import {setAllEntities, updateEntity, withEntities} from '@ngrx/signals/entities';
import {rxMethod} from '@ngrx/signals/rxjs-interop';
import type moment from 'moment';
import {pipe, switchMap, tap} from 'rxjs';
import type {
    SortBy,
    SortOrder,
    WorkTimeControllingTableData,
} from '../work-time-controlling-table/work-time-controlling-table.models';

type State = {
    isLoading: boolean;
    selectedMonth: moment.Moment;
    sortOrder: SortOrder;
    sortBy: SortBy;
};

const initialState: State = {
    isLoading: false,
    selectedMonth: DvbDateUtil.startOfMonth(DvbDateUtil.today()),
    sortOrder: 'asc',
    sortBy: 'displayname',
};

const HUNDRED = 100;

function sumHours(
    controlData: JaxWorkTimeControl,
    accessor: (daily: JaxWorkTimeControlDailyData) => number,
): number {
    return Math.round(
        controlData.dailyControlData.map(daily => accessor(daily))
            .reduce((a, b) => a + b, 0) * HUNDRED) / HUNDRED;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MandantWorkTimeControllingStore = signalStore(
    withDevtools('workTimeControl'),
    withEntities<WorkTimeControllingTableData>(),
    withState<State>(initialState),
    withComputed(
        store => ({
            sortedData: computed<WorkTimeControllingTableData[]>(() => {
                const entities = store.entities();
                const sortBy = store.sortBy();
                const sortOrder = store.sortOrder() === 'asc' ? 1 : -1;

                return entities.slice().sort((a, b) => {
                    switch (sortBy) {
                        case 'targetHours':
                            return (a.targetHours - b.targetHours) * sortOrder;
                        case 'plannedHours':
                            return (a.plannedHours - b.plannedHours) * sortOrder;
                        case 'displayname':
                        default:
                            return a.angestellteDisplayName.localeCompare(b.angestellteDisplayName) * sortOrder;
                    }
                });
            }),
        }),
    ),
    withMethods((
        store,
        service: WorkTimeControlService = inject(WorkTimeControlService),
    ) => ({
        startLoading() {
            patchState(store, {isLoading: true});
        },
        load: rxMethod<moment.Moment>(pipe(
            tap(() => patchState(store, {isLoading: true})),
            switchMap(selectedMonth => service.getAll$({
                    worktimecontrol: {
                        stichtag: DvbRestUtil.momentToLocalDateChecked(selectedMonth),
                    },
                }).pipe(
                    handleResponse({
                        next: (data: JaxWorkTimeControls) => {
                            const wtcData: WorkTimeControllingTableData[] = data.workTimeControlData.map(d => ({
                                id: d.angestellteId,
                                angestellteDisplayName: d.angestellteDisplayName,
                                targetHours: sumHours(d, daily => daily.targetHours),
                                plannedHours: sumHours(d, daily => daily.plannedHours),
                                ist: 0,
                                abwesenheit: 0,
                                ferienkontingent: 0,
                                expanded: false,
                                dailyHours: d.dailyControlData.map(daily => {
                                    return {
                                        expanded: false,
                                        date: daily.date,
                                        targetHours: daily.targetHours,
                                        plannedHours: daily.plannedHours,
                                        ist: 0,
                                        abwesenheit: 0,
                                        ferienkontingent: 0,
                                    };
                                }).sort((a, b) => a.date.localeCompare(b.date)),
                            }));

                            patchState(store, setAllEntities(wtcData));
                        },
                        finalize: () => patchState(store, {isLoading: false}),
                    }),
                ),
            ),
        )),
        applyMonth(month: moment.Moment) {
            patchState(store, {selectedMonth: month});
        },
        toggleSort(sortBy: SortBy) {
            patchState(store, {
                sortBy,
                sortOrder: store.sortBy() === sortBy && store.sortOrder() === 'asc' ? 'desc' : 'asc',
            });
        },
        toggleAngestellte(angestellteId: string) {
            const current = store.entityMap()[angestellteId];
            patchState(store, updateEntity({id: angestellteId, changes: {expanded: !current.expanded}}));
        },
        toggleDay(angestellteId: string, date: BackendLocalDate) {
            const current = store.entityMap()[angestellteId];
            const dailyHours = current.dailyHours.slice();
            const dateIndex = dailyHours.findIndex(daily => daily.date === date);
            if (dateIndex < 0) {
                return;
            }
            dailyHours[dateIndex] = {...dailyHours[dateIndex], expanded: !dailyHours[dateIndex].expanded};

            patchState(store, updateEntity({id: angestellteId, changes: {dailyHours}}));
        },
    })),
    withHooks({
        onInit(store) {
            store.load(store.selectedMonth);
        },
    }),
);
