import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'dv-work-time-settings',
    standalone: true,
    imports: [],
    templateUrl: './work-time-settings.component.html',
    styles: `
        :host {
            display: block;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkTimeSettingsComponent {
}
