import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {DateSwitcherComponent, SpinnerComponent} from '@dv/shared/angular';
import {WorkTimeControllingTableComponent} from '../work-time-controlling-table/work-time-controlling-table.component';
import {MandantWorkTimeControllingStore} from './mandant-work-time-controlling.store';

@Component({
    selector: 'dv-work-time-control',
    standalone: true,
    imports: [
        WorkTimeControllingTableComponent,
        DateSwitcherComponent,
        SpinnerComponent,
    ],
    templateUrl: './mandant-work-time-controlling.component.html',
    styles: `
        :host {
            display: block;
        }
    `,
    providers: [MandantWorkTimeControllingStore],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MandantWorkTimeControllingComponent {
    public readonly store = inject(MandantWorkTimeControllingStore);
}
